import { Observable } from 'rxjs';

import { Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AuditEvent, AuditEventType } from '@models/audit/audit-event';
import { Pageable } from '@models/audit/pageable';
import { AuditService } from '@services/audit.service';

@Component({
  selector: 'app-audit-table',
  templateUrl: './audit-table.component.html',
  styleUrls: ['./audit-table.component.scss'],
})
export class AuditTableComponent implements OnInit {
  @Input() idNumber: string;
  @Input() size: number;
  @Input() filterEnabled = false;
  events: Observable<Pageable<AuditEvent>>;
  sortColumn = 'eventTime';
  sortDirection = 'desc';
  page = 0;
  first: boolean;
  last: boolean;
  showFilters = false;
  loading = true;

  @ViewChildren('filters') filterInputs!: QueryList<ElementRef>;

  constructor(private auditService: AuditService) {}

  ngOnInit() {
    this.getEvents();
  }

  getEvents(page?: number) {
    // tslint:disable-next-line:triple-equals
    if (page == null) {
      this.page = 0;
    }
    this.loading = true;
    this.events = this.auditService.getAuditEvents(
      this.sortColumn,
      this.sortDirection,
      page === undefined || page === null ? this.page : page,
      this.size,
      this.filter(),
      this.idNumber
    );

    this.events.subscribe((res) => {
      this.page = res.pageable.pageNumber;
      this.first = res.first;
      this.last = res.last;
      this.loading = false;
    });
  }

  filter(): { [k: string]: string } {
    if (this.filterInputs === undefined) {
      return {};
    }
    return this.filterInputs
      .map((i) => {
        const e = i.nativeElement as HTMLInputElement | HTMLSelectElement;
        if (e.type === 'date') {
          // Convert dates to ISO 8601 format
          const currentDate = new Date();
          const [year, month, day] = e.value.split('-').map(Number);
          if (month !== undefined) {
            // If the field was blank, fall through to the default
            currentDate.setFullYear(year, month - 1, day);
            const currentHour = currentDate.getUTCHours();
            if(e.name === 'lowerBound'){
               // calculate and set the hours to 12:00 pm (previous day) from the selected day. 
               // setting the hours to either 12 or 24 based on currentHour 
               // then subracting or adding hours accordingly to get the results.
              if(currentHour >= 12) {
                currentDate.setUTCHours(currentHour + (24 - currentHour) - 12, 0, 0, 0);
              }else {
                currentDate.setUTCHours(currentHour + (12 - currentHour) - 24, 0, 0, 0);
              }  

              currentDate.setUTCMinutes(0);
              currentDate.setUTCSeconds(0);
            } else if (e.name === 'upperBound') {
              // calculate and set the hours to 11:59 am for the current day .
               // setting the hours to either 12 or 24 based on currentHour 
               // then adding hours accordingly to get the results.
              if(currentHour >= 12) {
                currentDate.setUTCHours((currentHour + (24 - currentHour) - 1) + 12, 0, 0, 0);
              }else {
                currentDate.setUTCHours((currentHour + (12 - currentHour) - 1), 0, 0, 0);
              }  
    
              currentDate.setUTCMinutes(59);
              currentDate.setUTCSeconds(59);
            }
            
            return [e.name, currentDate.toISOString()];
          }
        }
        return [e.name, e.value];
      })
      .filter(([_, val]) => val !== '') // only use fields with values
      .reduce((acc, [key, val]) => {
        acc[key] = val;
        return acc;
      }, {}); // transform to object (Target compilation doesn't have Object.fromEntries())
  }

  eventTypeString(eventType: AuditEventType): string {
    switch (eventType) {
      case 'ACCOUNT_RESET_DISABLED':
        return 'Reset Disabled';
      case 'ACCOUNT_RESET_ENABLED':
        return 'Reset enabled';
      case 'ACCOUNT_RESET_DISABLED_PASSWORD_MANGLED':
        return 'Login Prevented';
      case 'PASSWORD_SET':
        return 'Password Set';
      case 'PASSWORD_RESET':
        return 'Password Reset';
      case 'PASSWORD_CHANGE':
        return 'Password Change';
      case 'ACCOUNT_RESTRICTED_REMOVE':
        return 'Remove restricted from account';
      case 'ACCOUNT_RESTRICTED_ADD':
        return 'Add restricted to account';
    }
  }
}
