import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '@directives/directives.module';
import { IonicModule } from '@ionic/angular';

import { AuditTableComponent } from './audit-table/audit-table.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { IdentityTextComponent } from './identity-text/identity-text.component';
import { ContactBlockComponent } from './contact-block/contact-block.component';

@NgModule({
  declarations: [IdentityTextComponent, AuditTableComponent, FooterComponent, HeaderComponent, ContactBlockComponent],
  imports: [CommonModule, IonicModule, FormsModule, DirectivesModule, RouterModule],
  exports: [IdentityTextComponent, AuditTableComponent, FooterComponent, HeaderComponent, ContactBlockComponent],
})
export class SharedModule {}
