import { Component, Input, OnInit } from '@angular/core';
import { Identity } from '@models/identity/identity';

enum Status {
  Good,
  Warning,
  Error,
  Deceased,
}

@Component({
  selector: 'app-identity-text',
  templateUrl: './identity-text.component.html',
  styleUrls: ['./identity-text.component.scss'],
})
export class IdentityTextComponent implements OnInit {
  @Input() identity: Identity;
  moreDetail = false;
  status: Status;
  caption: string;
  tooltip: string;
  Status = Status;

  preferredFirst(a, _) {
    return a.key.includes('Preferred') ? -1 : 1;
  }

  constructor() {}

  ngOnInit() {
    const { caption, status, tooltip } = this.getStatus();
    this.caption = caption;
    this.status = status;
    this.tooltip = tooltip;
  }

  toggleMoreDetail(e: Event) {
    e.preventDefault();
    this.moreDetail = !this.moreDetail;
  }

  getStatus(): { caption: string; status: Status; tooltip: string } {
    interface State {
      caption: string;
      status: Status;
      matchIf: boolean;
      explanation: string;
    }
    const states: { [k: string]: State } = {
      dead: {
        caption: 'Deceased',
        status: Status.Deceased,
        matchIf: true,
        explanation: 'This person is deceased',
      },
      primaryIdentity: {
        caption: 'Merged',
        status: Status.Error,
        matchIf: false,
        explanation: 'This user account has been merged with another account, and this is not the primary identity',
      },
      loginDisabled: {
        caption: 'Account Disabled',
        status: Status.Error,
        matchIf: true,
        explanation: "This user's account has been disabled. Enable reset on the account to re-enable it.",
      },
      accountError: {
        caption: 'Account Error',
        status: Status.Error,
        matchIf: true,
        explanation: "This user's account has an error upstream - please contact the IAM team.",
      },
      restricted: {
        caption: 'Restricted',
        status: Status.Warning,
        matchIf: true,
        explanation: 'This user can only have changes to their account performed by security or other people with elevated privileges',
      },
      resetEnabled: {
        caption: 'No reset',
        status: Status.Warning,
        matchIf: false,
        explanation: 'This user cannot reset their password on their own, and must contact the university',
      },
      mustChangePassword: {
        caption: 'Must change',
        status: Status.Good,
        matchIf: true,
        explanation: 'This user will not be able to access university resources protected by Shibboleth until they change their password',
      },
    };

    const identityStates = Object.entries(this.identity)
      .filter(([key, val]) => key in states && val === states[key].matchIf)
      .map(([key, _]) => states[key]);

    if (identityStates.length > 0) {
      return {
        caption: identityStates.map((state) => state.caption)[0],
        status: Math.max(...identityStates.map((state) => state.status)),
        tooltip: identityStates.map((state) => `${state.caption}: ${state.explanation}`).join('\n'),
      };
    } else {
      return {
        caption: 'Active',
        status: Status.Good,
        tooltip: "This user's account has no special conditions - everything's normal",
      };
    }
  }
}
