import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AuditEvent } from '@models/audit/audit-event';
import { FinaliseEvent } from '@models/audit/finalise-event';
import { Pageable } from '@models/audit/pageable';
import { BypassErrorService } from '@uoa/error-pages';

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  public finaliseEvent: FinaliseEvent;
  url: string;

  constructor(private http: HttpClient, private _bypass: BypassErrorService) {
    this.url = environment.api.url;
  }

  addTicketNumber(auditId: string, ticketNumber: string): Observable<any> {
    const request = `${this.url}/audit/${auditId}/message`;
    this._bypass.bypassError(request, [404, 401, 403]);
    return this.http.post(request, `"${ticketNumber}"`, { headers: { 'Content-Type': 'application/json' } });
  }

  getAuditEvents(
    sortColumn: string,
    sortDirection: string,
    page: number,
    size?: number,
    filters?: { [k: string]: string },
    idNumber?: string
  ): Observable<Pageable<AuditEvent>> {
    const request = `${this.url}/audit/${idNumber ? idNumber : 'all'}`;
    this._bypass.bypassError(request, [404, 401, 403]);
    const params = {
      ...filters,
      size: size === undefined || size === null ? '15' : size.toString(),
      sort: `${sortColumn},${sortDirection}`,
      page: page.toString(),
    };
    return this.http
      .get<Pageable<AuditEvent>>(request, { params })
      .pipe(shareReplay());
  }
}
