import { Directive, ElementRef, NgZone, OnInit } from '@angular/core';

@Directive({
  selector: '[appAutoFocus]'
})
export class AutoFocusDirective implements OnInit {
  constructor(private ngZone: NgZone,
    private elemRef: ElementRef) {
  }

  ngOnInit() {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.elemRef.nativeElement.setFocus();
        // this.renderer.selectRootElement(this.elemRef.nativeElement).focus();
      }, 0);
    });

  }

}
