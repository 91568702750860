import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AccountService} from '@services/account.service';
import {map} from 'rxjs/operators';
import {NavController} from '@ionic/angular';


@Injectable()
export class GuardAdminOnlyLogin implements CanActivate {
    HTTP_403_ERROR = 403;

    constructor(private accountService: AccountService, private nav: NavController) {}

    // @ts-ignore
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.accountService.canLoginAsAdmin().pipe(map(canLoginAsAdmin =>{
            if(!canLoginAsAdmin.canLoginAsAdmin){
                this.nav.navigateRoot('error/'+this.HTTP_403_ERROR);
            }
            return true;
        }));
    }

}
