import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA,NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { AppStorageService } from '@services/app-storage.service';
import { SharedModule } from '@shared/shared.module';
import { AuthModule, CognitoConfigService, LoginService, StorageService } from '@uoa/auth';
import { ErrorPagesModule } from '@uoa/error-pages';

import { AppRoutingModule } from './app-routing.module';
import { AppAuthConfig } from './app.auth.config';
import { AppComponent } from './app.component';
import { GuardAdminOnlyLogin } from './auth/guard/GuardAdminOnlyLogin';



@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        AuthModule,
        SharedModule,
        ErrorPagesModule,
    ],
    providers: [
        StatusBar,
        SplashScreen,
        IonicStorageModule,
        LoginService,
        GuardAdminOnlyLogin,
        { provide: CognitoConfigService, useClass: AppAuthConfig },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: StorageService, useClass: AppStorageService },
    ],
    bootstrap: [AppComponent],
    schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
