import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AuditEvent } from '@models/audit/audit-event';
import { BypassErrorService } from '@uoa/error-pages';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  url: string;

  constructor(private http: HttpClient, private _bypass: BypassErrorService) {
    this.url = environment.api.url;
  }

  setPassword(usernameOrId: string, password: string): Observable<AuditEvent> {
    const request = `${this.url}/account/set?passwordChangeType=PASSWORD_SET`;
    this._bypass.bypassError(request, [404, 401, 403]);
    return this.http.post<AuditEvent>(request, JSON.stringify({ usernameOrId, password }), {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  disableLogin(id: string): Observable<AuditEvent> {
    const request = `${this.url}/account/${id}/restrictions/login`;
    this._bypass.bypassError(request, [404, 401, 403]);
    return this.http.post<AuditEvent>(request, null, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  setPasswordReset(id: string, enabled: boolean): Observable<AuditEvent> {
    const request = `${this.url}/account/${id}/restrictions/reset`;
    this._bypass.bypassError(request, [404, 401, 403]);
    const options = { headers: { 'Content-Type': 'application/json' } };

    return enabled ? this.http.delete<AuditEvent>(request, options) : this.http.post<AuditEvent>(request, options);
  }

  isAuthorised(): Observable<any> {
    const request = `${this.url}/account/authorised`;
    this._bypass.bypassError(request, [404, 401, 403]);
    return this.http.get<any>(request, { headers: { 'Content-Type': 'application/json' } });
  }

  canLoginAsAdmin(): Observable<any> {
    const request = `${this.url}/account/isAdminUser`;
    this._bypass.bypassError(request, [404, 401, 403]);
    return this.http.get<any>(request, { headers: { 'Content-Type': 'application/json' } });
  }
  setTicketNumber(eventId: string, ticketNumber: string): Observable<string> {
    const request = `${this.url}/audit/${eventId}/message`;
    this._bypass.bypassError(request, [404, 401, 403]);
    return this.http.post<string>(request, JSON.stringify(ticketNumber), {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  generatePassword(length: number, serviceAccount: boolean): string {
    // b64 representations are always multiples of 4, so we make an array one byte larger than the max password size
    // (39 bytes -> 52char rep > 50 char limit) and trim afterwards
    const randomNumbers = new Uint8Array(39);

    let password: string; // always fail the first time to get into while loop
    const notAcceptable = serviceAccount ? () => false : (p) => /(\+|\/|=)/.test(p);

    while (password === undefined || notAcceptable(password)) {
      // retry until we get a password with no special chars. Inefficient, but not so arduous as to be worth a fuss
      window.crypto.getRandomValues(randomNumbers);
      password = btoa(String.fromCharCode.apply(null, randomNumbers)).substring(0, length);
      if (!serviceAccount) {
        password = password.toLowerCase();
      }
    }

    return password;
  }

  phoneticAlphabetPronunciation(password: string): string {
    // e.g. ab CD 12! => alpha bravo ␣ CHARLIE DELTA one two !
    const alphabet: { [key: string]: string; }= {
      a: 'alpha',
      b: 'bravo',
      c: 'charlie',
      d: 'delta',
      e: 'echo',
      f: 'foxtrot',
      g: 'golf',
      h: 'hotel',
      i: 'india',
      j: 'juliett',
      k: 'kilo',
      l: 'lima',
      m: 'mike',
      n: 'november',
      o: 'oscar',
      p: 'papa',
      q: 'quebec',
      r: 'romeo',
      s: 'sierra',
      t: 'tango',
      u: 'uniform',
      v: 'victor',
      w: 'whiskey',
      x: 'x-ray',
      y: 'yankee',
      z: 'zulu',
      0: 'zero',
      1: 'one',
      2: 'two',
      3: 'three',
      4: 'four',
      5: 'five',
      6: 'six',
      7: 'seven',
      8: 'eight',
      9: 'nine',
      ' ':'␣'
    };

    return password
      .split('')
      .map((i) => {
        let phonetic = alphabet[i.toLowerCase()];
        if(!phonetic){
          // for special characters that aren't in our dictionary
          phonetic = i;
        } else if (i.toUpperCase() === i){
          phonetic = phonetic.toUpperCase();
        } 

        return phonetic;
      })
      .join(' ');
  }
}
