import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppStorageService } from '@services/app-storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  searchTerm: string;

  constructor(private router: Router, private appStorageService: AppStorageService) {}

  isAdmin(): boolean {
    return this.router.url.indexOf('admin') > 0;
  }

  logoLink() {
    if (this.isAdmin()) {
      this.router.navigate(['/admin/search']);
    } else {
      this.router.navigate(['/']);
    }
  }

  search(searchTerm: string) {
    // tslint:disable-next-line:triple-equals
    if (searchTerm !== null && searchTerm !== undefined) {
      this.router.navigate(['/admin/search', searchTerm]);
      this.appStorageService.setItem("searchTerm", searchTerm);
    }
  }
}
